<template>
  <div id="root">
    <div id="appMenu">
      <navigation
          :plugins="plugins"
      ></navigation>
    </div>
    <div id="appRouterView" :style="style">
      <router-view/>
    </div>
    <!-- Extract to filter component -->
    <svg
        height="0"
        xmlns="http://www.w3.org/2000/svg"
    >
      <filter id="drop-shadow">
        <feGaussianBlur
            in="SourceAlpha"
            stdDeviation="9"
        />
        <feOffset
            dx="2"
            dy="3"
            result="offsetblur"
        />
        <feFlood :flood-color="shadowColor"/>
        <feComposite
            in2="offsetblur"
            operator="in"
        />
        <feMerge>
          <feMergeNode/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      </filter>
    </svg>
  </div>
  <Footer
      v-if="PARTNERS?.length"
      id="footer"
      :partners="PARTNERS"
  ></Footer>
</template>

<script>
import * as actions from './store/graph/operations'
import {CONTINUE_SESSION} from './store/graph/operations'
import {computed, watch} from "vue";
import {useDarkmode} from "@/components/use/useDarkmode";
import {useStore} from "vuex";
import {useService} from "@/components/use/serviceAssets/useService";
import Navigation from './components/site/Navigation.vue'
import Footer from '@/components/Footer'

import config from '../../config.js'

const {
  PARTNERS,
  PLUGINS,
  PRIMARY_BACKGROUND_COLOR,
  PRIMARY_COLOR,
  SERVICE_ACRONYM,
} = config
export default {
  name: 'App',
  components: {
    Navigation,
    Footer,
  },
  setup() {
    document.title = SERVICE_ACRONYM
    const store = useStore()
    const {loadService} = useService()
    const style = computed(() => {
      const style = {}
      if (PARTNERS?.length) style.paddingBottom = '6rem'
      return style
    })
    loadService(SERVICE_ACRONYM)

    const {darkmode} = useDarkmode()
    const setPageLightmode = () => {
      const isDark = darkmode.value ?? false
      const lightmodeClass = isDark ? 'uk-dark' : 'uk-light'
      const otherClass = lightmodeClass === 'uk-dark' ? 'uk-light' : 'uk-dark'

      document.body.classList.add(lightmodeClass)
      document.body.classList.remove(otherClass)
    }

    const registerWindowResizeEventListener = () => window.addEventListener('resize', handleWindowResize)
    const registerScreenOrientationChangeListener = () => window.addEventListener("orientationchange", handleScreenOrientationChange)

    const resumeSession = () => {
      store.commit(CONTINUE_SESSION)
    }
    const unregisterListeners = () => {
      window.removeEventListener('resize', handleWindowResize)
      window.removeEventListener('orientationchange', handleScreenOrientationChange)
    }
    const handleScreenOrientationChange = () => {
      store.commit(actions.PUT_SCREEN_ORIENTATION, screen.orientation)
    }
    const handleWindowResize = () => {
      const clientDimensions = {
        width: window.innerWidth,
        height: window.innerHeight,
      }
      store.commit(actions.PUT_WINDOW_DIMENSIONS, clientDimensions)
    }

    const shadowColor = computed(() => darkmode.value ? '#808080' : 'white)')
    watch(darkmode, setPageLightmode)
    const plugins = computed(() => PLUGINS ?? [])
    return {
      onDragOver: () => null, // support ending drags anywhere (fx. for vFeedback #nitPicker)
      handleWindowResize,
      registerWindowResizeEventListener,
      registerScreenOrientationChangeListener,
      resumeSession,
      setPageLightmode,
      shadowColor,
      style,
      unregisterListeners,
      PARTNERS,
      plugins,
      PRIMARY_BACKGROUND_COLOR,
      PRIMARY_COLOR,
    }
  },
  mounted() {
    this.setPageLightmode()
    this.registerWindowResizeEventListener()
    this.registerScreenOrientationChangeListener()
    this.$nextTick(this.handleWindowResize)
    this.resumeSession()
  },
  unmounted() {
    this.unregisterListeners()
  },
}
</script>

<style>
:root {
  --smokeWhite: #e5e5e5;
  --smokeWhite2: #c8c8c8;
  --smokeBlack: #1a1a1a;
  --smokeBlack2: #3b3b3b;
  --darkMuted: #484848;
  --linkBlue: #1e87f0;
  --menuBarSize: 50px;
  --navbarColor: v-bind(PRIMARY_COLOR);
}

#root {
  display: flex;
  align-items: stretch;
  flex-flow: column;
  min-height: 100vh;
}

#appMenu {
  flex: 0 1 auto;
  background-color: v-bind(PRIMARY_BACKGROUND_COLOR);
}

#appRouterView {
  flex: 1;
  display: flex;
  justify-content: center;
}

#appRouterView > * {
  flex: 1;
}

#footer {
  max-height: 6rem;
  flex: 0 1 20px;
}
</style>
